import React from 'react';
import Header from './Header';
import Footer from './Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="bg-gray-100 py-10 px-6">
        <div className="max-w-7xl mx-auto  p-6">
          <h2 className="text-3xl font-bold mb-6 text-gray-800">Privacy Policy</h2>

          <section className="mb-6">
            <h3 className="text-xl font-semibold text-gray-700">Introduction</h3>
            <p className="text-gray-600 mt-2">
              Trinity Plus Social Trust is committed to protecting the privacy of our website users. This privacy policy outlines how we collect, use, and safeguard your information. 
              By using our services, you agree to the collection and use of information in accordance with this policy.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-semibold text-gray-700">About Trinity Plus Social Trust</h3>
            <p className="text-gray-600 mt-2">
              Genesis of <strong>Trinity Plus Social Trust</strong>: Established in Tamil Nadu on 10/11/2008 (Registration No: 617/2008), Trinity Plus Social Trust 
              is dedicated to uplifting marginalized and socially weaker sections through programs in Education, Healthcare, Agriculture, and Livelihood. 
              Our initiatives have empowered over 300,000 students with career guidance, counseling, and job-oriented skill development. 
              We are committed to transparency, accountability, and effective resource utilization in all our activities.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-semibold text-gray-700">Information We Collect</h3>
            <p className="text-gray-600 mt-2">
              We collect information that you provide directly to us, such as when you fill out a form, contact us, or use our services. This may include your name, 
              email address, phone number, and any other information you provide. Additionally, we may collect information automatically through cookies and similar technologies 
              to improve your experience on our website.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-semibold text-gray-700">How We Use Your Information</h3>
            <p className="text-gray-600 mt-2">
              The information collected is used to improve our services, respond to inquiries, and keep you informed about our activities and events. 
              We may use your information to tailor our communications to better meet your needs and interests. We do not sell or share your personal information with third parties.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-semibold text-gray-700">Data Security</h3>
            <p className="text-gray-600 mt-2">
              We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. 
              However, please note that no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-semibold text-gray-700">Your Rights</h3>
            <p className="text-gray-600 mt-2">
              You have the right to access, correct, or delete your personal information. If you wish to exercise these rights or have any questions about this privacy policy, 
              please contact us at trinityplussocial@gmail.com . We will respond to your request as soon as possible.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-semibold text-gray-700">Contact Us</h3>
            <p className="text-gray-600 mt-2">
              If you have any questions or concerns regarding our privacy practices or this policy, please reach out to us at:
            </p>
            <ul className="text-gray-600 mt-2 list-disc list-inside">
              <li>Email: trinityplussocial@gmail.com</li>
              <li>Phone: 91+ 9514 222122</li>
              <li>Address: Trinity Plus Social Trust Office,Tiruchirappalli, Tamilnadu</li>
            </ul>
          </section>

          <section className="mb-6">
            <h3 className="text-xl font-semibold text-gray-700">Updates to This Policy</h3>
            <p className="text-gray-600 mt-2">
              We may update this privacy policy from time to time to reflect changes in our practices. We encourage you to review this page periodically for the latest information 
              on our privacy practices. The last update to this policy was made on October 15, 2024.
            </p>
          </section>

          <p className="text-gray-600 text-sm mt-6">
            Copyright © 2024. All Rights Reserved. — Designed by Tekno Spot
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
