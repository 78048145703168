import React, { useState, useEffect } from 'react';

const ImageSlider = () => {
  const images = [
    { src: 'img/IMG_20221202_132407.jpg', alt: 'Event 1' },
    { src: 'img/IMG_20220506_125035.jpg', alt: 'Event 2' },
    { src: 'img/event-3.png', alt: 'Event 3' },
    { src: 'img/event-4.png', alt: 'Event 4' },
    { src: 'img/IMG_20221222_153809(1).jpg', alt: 'Event 5' }
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // change image every 3 seconds

    return () => clearInterval(interval); // clean up interval on component unmount
  }, [images.length]);

  return (
    <div className="p-4">
      <div className="max-w-[1250px] mx-auto relative w-full h-[260px] sm:h-[360px] md:h-[460px] lg:h-[560px]">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              index === currentImageIndex ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-full object-cover rounded-[10px]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
