import React from 'react';

const Footer = () => {
    return (
        <footer className=" bg-green-800 text-white py-5 px-10">
            <div className="container mx-auto px-10 ">
                <div className="flex flex-col lg:flex-row ">
                    {/* Logo and Address */}
                    <div className=" w-[250px] lg:w-[800px] mb-6 lg:mb-0 flex flex-col lg:flex-row">
                        <a href="/"><img
                            src="img/trinity-logo.png"
                            alt="Logo"
                            className="w-48 h-auto mb-4 lg:mb-0 lg:mr-4 lg:w-48 lg:h-20 object-contain transition-transform transform hover:scale-110 duration-300 "
                        /></a>
                        <div className="flex-1">
                            <a href="/contact"><h2 className="font-bold mb-2 text-white">Contact Us</h2></a>
                            <p className="lg:mb-5 mb-3 text-[13px] md:text-[16px]">Email: <span className='md:text-base'>trinityplussocial@gmail.com</span></p>
                            <p className="lg:mb-5 mb-3 text-[13px] md:text-[16px]">Phone: <span className='md:text-base'>91+ 95142 22122</span></p>
                            <p className="lg:mb-5 mb-3 text-[13px] md:text-[16px]">
                                Address: <span className='md:text-base'> Trinity Plus Social Trust Office,</span> <br />
                                <span className="ml-14 lg:ml-[70px]">Tiruchirappalli,Tamilnadu </span><br />

                            </p>
                        </div>
                    </div>

                    {/* Quick Navigation */}
                    <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                        <h3 className="font-semibold mb-4">Quick Navigation</h3>
                        <ul>
                            <li className='mb-2'>
                                <a href="/" className="hover:text-black">
                                    Home
                                </a>
                            </li>
                            <li className='mb-2'>
                                <a href="/about" className="hover:text-black">
                                    About
                                </a>
                            </li>
                            <li className='mb-2'>
                                <a href="/projects" className="hover:text-black">
                                    Projects
                                </a>
                            </li>
                            <li className='mb-2'>
                                <a href="/services" className="hover:text-black">
                                    Services
                                </a>
                            </li>
                            <li className='mb-2'>
                                <a href="/contact" className="hover:text-black">
                                    Contact
                                </a>
                            </li>
                            <li className='mb-2'>
                                <a href="/privacy"><h3 className="hover:text-black">Privacy Policy</h3></a>
                            </li>


                        </ul>
                    </div>

                    {/* Follow Us */}
                    <div className="w-full lg:w-1/4">
                        <h3 className="font-semibold mb-4">Follow Us</h3>
                        <div className="flex lg:justify-start space-x-4">
                            <i className="fab fa-facebook cursor-pointer bg-blue-600 rounded-lg p-1 object-contain transition-transform transform hover:scale-110 duration-300 hover:shadow-2xl"></i>
                            <i className="fab fa-twitter cursor-pointer bg-blue-400 rounded-lg p-1 object-contain transition-transform transform hover:scale-110 duration-300 hover:shadow-2xl"></i>
                            <a href="https://www.youtube.com/@TRINITYPLUSSOCIALTRUST-cl5dp"><i className="fab fa-youtube cursor-pointer bg-red-600 rounded-lg p-1 object-contain transition-transform transform hover:scale-110 duration-300 hover:shadow-2xl"></i></a>
                        </div>
                    </div>
                </div>

            </div>
            <div className="mt-8 border-t border-gray-100 pt-4">
                <a href="https://teknospot.in/"><p className="text-center text-sm text-white">Copyright © 2024. All Rights Reserved. — Designed by Tekno Spot</p></a>
            </div>
        </footer>
    );
};

export default Footer;
