import Header from "./Header";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import Footer from "./Footer";

function About() {

    useEffect(() => {
        Aos.init({
            // Global settings:
            delay: 200, // Delay in ms (100ms = 0.1s)
            duration: 1000, // Animation duration in ms
            once: true, // Whether animation should happen only once - while scrolling down
        });
    }, []);

    return (
        <>
            <Header></Header>
            <section className="relative bg-white">
                <div className="relative">
                    <img
                        src="img/istockphoto-963192098-612x612.jpg"
                        alt="About Us"
                        className="w-full object-cover h-64 md:h-80 lg:h-96 bg-black"
                    />
                    <div data-aos="fade-up" className="absolute bottom-4 left-4 md:left-8 lg:left-16 top-1/2 transform -translate-y-1/2 p-4 font-bold text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
                        About Us
                    </div>
                </div>
            </section>
            <section className="bg-white py-8 px-4 sm:px-8 md:px-16 lg:px-24">
                <div className="max-w-7xl mx-auto">
                    <h2 className="text-yellow-500 text-2xl sm:text-xl md:text-3xl lg:text-4xl text-center font-bold mb-4 mt-5">
                        About Trinity Plus Social Trust
                    </h2>
                    <p className="ani text-black mb-0 text-base sm:text-lg lg:text-lg text-justify">
                        TRINITY Plus Social Trust (TPST) is working in the admin Blocks namely Manapparai, Vaiyampatti, in Thiruchirappalli District, Tamilnadu, India with the population covered about 1,86,500 spread over 136 villages panchayats. TPST has all legal amenities such as Full Tax Exemption to Donors u/s 80G of Indian Income Tax and Sec. 12A(a), PAN and TAN numbers. TPST works with a Vision:<br />
                        Our Mission focuses on: Vocational Skill Development Training for rural youths, Career Guidance and Counseling for education and employment, women SHGs and Farmers Groups (FIG) formation, School Students' Environment and Biodiversity conservation awareness, Community Health Awareness Programs, Rehabilitation services for differently-abled persons, and Leadership training.<br />
                        TPST is governed by a democratically constituted Board of Trustees with 6 Members, and the Executive Committee has 4 members. The day-to-day affairs are managed by the Managing Trustee – Mr. R. BHASKARAN M.B.A., with 8 dedicated Staffs.
                    </p>
                </div>

                <div className="mt-5 lg:mt-16 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-10">
                    {/* Vision Section */}
                    <div className="relative w-full md:w-[350px] h-[350px] bg-white rounded-[20px] shadow-[0px_0px_30px_1px_#00000040] flex flex-col justify-center items-center p-4 transition-transform transform hover:scale-105">
                        <img
                            src="img/Sailor looking through telescope off the mast.png"
                            alt="Vision"
                            className="w-[150px] h-[150px] md:w-[200px] md:h-[200px] object-cover"
                        />
                        <h4 className="text-[#1f783f] font-inter font-bold mb-4 text-center text-[24px] leading-[19.36px]">
                            Vision
                        </h4>
                        <p className="text-[16px] leading-[19.36px] font-normal font-inter text-center lg:mb-44">
                            Empowering individuals from disadvantaged backgrounds to achieve their full potential and contribute positively to society.
                        </p>
                    </div>

                    {/* Mission Section */}
                    <div className="relative w-full md:w-[350px] h-[350px] bg-white rounded-[20px] shadow-[0px_0px_30px_1px_#00000040] flex flex-col justify-center items-center  p-4 transition-transform transform hover:scale-105">
                        <img
                            src="img/Reaching the goal.png"
                            alt="Mission"
                            className="w-[150px] h-[150px] md:w-[200px] md:h-[200px] object-cover"
                        />
                        <h4 className="font-inter text-[#1f783f] font-bold mb-4 text-center text-[24px] leading-[19.36px]">Mission</h4>
                        <p className="text-[16px] leading-[19.36px] font-normal font-inter  text-center  lg:mb-40">
                            To provide holistic support and opportunities for education, career guidance, skill development, and livelihood creation, with a focus on empowering marginalized communities and supporting their families.
                        </p>
                    </div>
                </div>
            </section>



            <div className="p-4 sm:p-6 md:p-8 flex flex-col lg:flex-row lg:space-x-10">

                {/* Left Side: Objectives and Core Values */}
                <div className="flex-1 mb-8 sm:ml-16 lg:ml-10">
                    <h1 className="text-[#1f783f] font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-5">Objectives</h1>
                    <div className="w-full max-w-[800px] text-base sm:text-lg md:text-lg lg:text-xl">
                        <p className="mb-3">Provide comprehensive career guidance and counseling services to individuals from poor and disadvantaged groups.</p>
                        <p className="mb-3">Support the education of underprivileged students through scholarships and educational assistance programs.</p>
                        <p className="mb-3">Promote skill development initiatives to enhance employability and entrepreneurship among marginalized youth.</p>
                        <p className="mb-3">Facilitate livelihood creation for disadvantaged communities, with a special focus on supporting mothers and enhancing their economic independence.</p>
                        <p className="mb-3">Offer de-addiction counseling and support services to individuals struggling with substance abuse.</p>
                        <p className="mb-3">Raise funds and resources to sustain and expand our programs and initiatives.</p>
                        <p className="mb-3">Provide social, economic, and technological support to enable marginalized communities to thrive and succeed.</p>
                    </div>

                    <h1 className="text-[#1f783f] font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl mt-8 mb-2">Core Values</h1>
                    <div className="w-full max-w-[800px] text-base sm:text-lg md:text-lg lg:text-xl">
                        <p className="mb-3"><span className="text-[#1f783f] font-semibold">Empowerment:</span> We believe in empowering individuals to take control of their lives and realize their aspirations.</p>
                        <p className="mb-3"><span className="text-[#1f783f] font-semibold">Compassion:</span> We demonstrate empathy, kindness, and compassion in our interactions with beneficiaries and stakeholders.</p>
                        <p className="mb-3"><span className="text-[#1f783f] font-semibold">Integrity:</span> We uphold honesty, transparency, and ethical conduct in all our dealings.</p>
                        <p className="mb-3"><span className="text-[#1f783f] font-semibold">Collaboration:</span> We foster collaboration and partnerships with like-minded organizations, government agencies, and community stakeholders to maximize impact and reach.</p>
                        <p className="mb-3"><span className="text-[#1f783f] font-semibold">Innovation:</span> We embrace innovation and creativity in finding solutions to social challenges and improving our programs and services.</p>
                        <p className="mb-3"><span className="text-[#1f783f] font-semibold">Respect:</span> We respect the dignity, diversity, and rights of all individuals, regardless of their background or circumstances.</p>
                    </div>
                </div>

                {/* Right Side: Image */}
                <div className="flex-shrink-0 flex justify-center">
                    <img src="img/certificate.png" alt="Certificate" className="w-full max-w-[300px] sm:max-w-[400px] md:max-w-[500px] lg:max-w-[600px] xl:max-w-[700px]" />
                </div>
            </div>


            <div className="flex justify-center items-center">
                <h1 className=" text-red-500 font-bold text- font-montserrat text-2xl lg:text-4xl text-center mt-5 lg:mt-10 mb-5">PILLARS OF TRINITY</h1>
            </div>
            {/* Profile Image------1 */}
            <div className="flex flex-col lg:flex-row lg:p-5">
                <div className="rounded-full border-4 mt-5 border-gray-400 overflow-hidden w-32 h-32 md:w-48 md:h-48 lg:w-64 lg:h-64 lg:mr-10 lg:mb-6 mx-auto lg:mx-0">
                    <img
                        src="img\Bhaskaran-Trinity-Plus-qmlkkroes6ej5k1665r5p1i8qqgm5a611p0wqbmbuw.jpeg"
                        alt="Profile of Bhaskaran Rengaswamy"
                        className="object-cover w-full h-full lg:w-[300px] lg:h-[280px]"
                    />
                </div>

                {/* Info Section */}
                <div className="flex flex-col lg:flex-row flex-1 p-4 space-y-4 lg:mt-0 lg:space-y-0 lg:space-x-6 ">
                    {/* Divider with Icon */}
                    <div className="hidden lg:flex flex-col items-center w-full lg:w-auto justify-center space-y-2">
                        <div className="border-l-2 border-yellow-400 h-24 lg:h-44"></div>
                        <span className="text-2xl text-yellow-500">✦</span>
                        <div className="border-l-2 border-yellow-400 h-24 lg:h-44"></div>
                    </div>

                    {/* Main Info */}
                    <div className="flex flex-col items-center  text-center lg:text-left space-y-4 lg:space-y-6">
                        {/* Name Section */}
                        <h1 className="lg:text-2xl text-xl font-semibold font-serif text-green-800">
                            Bhaskaran Rengaswamy
                        </h1>

                        {/* Title Section */}
                        <div className="text-center text-xs md:text-sm uppercase font-light tracking-widest">
                            <p>Managing Trustee,</p>
                            <p>trinity plus social trust</p>
                        </div>


                        {/* Contact Info Section */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-4 text-center mt-2">
                            <div>
                                <a href="https://www.trinityplus.org" className="text-xs md:text-sm text-gray-600 hover:text-black">
                                    🌐 www.trinityplus.org
                                </a>
                            </div>
                            <div>
                                <a href="mailto:trinityplussocial@gmail.com" className="text-xs md:text-sm text-gray-600 hover:text-black">
                                    ✉️ trinityplussocial@gmail.com
                                </a>
                            </div>
                            <div>
                                <p className="text-xs md:text-sm text-black">📞 +91 8248933317</p>
                            </div>
                            <div>
                                <p className="text-xs md:text-sm text-black">📍 Tiruchirappalli, Tamil Nadu</p>
                            </div>
                        </div>


                        {/* Bio Section */}
                        <div className="max-w-[1100px] mx-auto p-4">
                            <p className="text-black mb-6 text-base lg:text-lg text-justify">
                                R. Bhaskaran, Managing Trustee of Trinity Plus Social Trust, has 14 years of experience in career counseling and skill-building. With an MBA in HR, he has authored Career Puzzle and three books on soft skills and communication. As a resource person for government initiatives like Naan Mudhalvan, Uyarvuku Padi, and Maperum Tamil Kanavu, he has guided over 3 lakh students. He has also led skill development programs with Tamil Nadu Skill Development Corporation, NABARD, and the Handicraft Department’s special program for empowering women. Recently, he started de-addiction recovery guidance.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Profile Image------2 */}
            <div className="flex flex-col lg:flex-row lg:p-5">
                <div className="rounded-full border-4 mt-5 border-gray-400 overflow-hidden w-32 h-32 md:w-48 md:h-48 lg:w-64 lg:h-64 lg:mr-10 lg:mb-6 mx-auto lg:mx-0">
                    <img
                        src="img\Screenshot_2024-09-13_153150-removebg-preview.png"
                        alt="Profile of Bhaskaran Rengaswamy"
                        className="object-cover w-full h-full lg:w-[300px] lg:h-[280px]"
                    />
                </div>

                {/* Info Section */}
                <div className="flex flex-col lg:flex-row flex-1 p-4 space-y-4 lg:mt-0 lg:space-y-0 lg:space-x-6 ">
                    {/* Divider with Icon */}
                    <div className="hidden lg:flex flex-col items-center w-full lg:w-auto justify-center space-y-2">
                        <div className="border-l-2 border-yellow-400 h-24 lg:h-64"></div>
                        <span className="text-2xl text-yellow-500">✦</span>
                        <div className="border-l-2 border-yellow-400 h-24 lg:h-64"></div>
                    </div>

                    {/* Main Info */}
                    <div className="flex flex-col items-center  text-center lg:text-left space-y-4 lg:space-y-6">
                        {/* Name Section */}
                        <h1 className="lg:text-2xl text-xl font-semibold font-serif text-green-800">
                            Arulvel Santhanam
                        </h1>

                        {/* Title Section */}
                        <div className="text-center text-xs md:text-sm uppercase font-light tracking-widest">
                            <p> Financial Trustee, </p>
                            <p>trinity plus social trust</p>
                        </div>
                        
                        {/* Contact Info Section */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-4 text-center mt-2">
                            <div>
                                <a href="https://www.trinityplus.org" className="text-xs md:text-sm text-gray-600 hover:text-black">
                                    🌐 www.trinityplus.org
                                </a>
                            </div>
                            <div>
                                <a href="mailto:trinityplussocial@gmail.com" className="text-xs md:text-sm text-gray-600 hover:text-black">
                                    ✉️ trinityplussocial@gmail.com
                                </a>
                            </div>
                            <div>
                                <p className="text-xs md:text-sm text-black">📞 +91 9944377012</p>
                            </div>
                            <div>
                                <p className="text-xs md:text-sm text-black">📍 Tiruchirappalli, Tamil Nadu</p>
                            </div>
                        </div>

                        {/* Bio Section */}
                        <div className="max-w-[1100px] mx-auto p-4">
                            <p className="text-black mb-6 text-base lg:text-lg text-justify">
                                Arulvel  Santhanam serves as the Financial Trustee of Trinity Plus Social Trust. He holds an MBA and brings over 10 years of experience in community development and skill development programs. Arulvel is instrumental in managing the trust's financial planning and resource allocation, ensuring the efficient and transparent execution of all initiatives.</p>
                            <p className="text-black mb-6 text-base lg:text-lg text-justify">
                                "Growing up in a rural community, I witnessed firsthand the struggles faced by marginalized individuals. This experience fueled my passion for community development and education. My journey with Trinity Plus Social Trust is driven by a commitment to create sustainable change. I believe that financial transparency and effective resource management are key to empowering communities. Every initiative we undertake is a step towards a brighter, more equitable future. Together, we can transform lives and build resilient communities."
                            </p>

                            <div className="flex justify-center ">
                                <h1 className="text-[#1f783f] font-sans font-bold text-lg lg:text-2xl text-center">
                                    “Empowering Lives, Transforming Communities“
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Profile Image------3 */}
            <div className="flex flex-col lg:flex-row lg:p-5">
                <div className="rounded-full border-4 mt-5 border-gray-400 overflow-hidden w-32 h-32 md:w-48 md:h-48 lg:w-64 lg:h-64 lg:mr-10 lg:mb-6 mx-auto lg:mx-0">
                    <img
                        src="img\Screenshot_2024-09-13_153211-removebg-preview.png"
                        alt="Profile of Bhaskaran Rengaswamy"
                        className="object-cover w-full h-full lg:w-[300px] lg:h-[280px]"
                    />
                </div>

                {/* Info Section */}
                <div className="flex flex-col lg:flex-row flex-1 p-4 space-y-4 lg:mt-0 lg:space-y-0 lg:space-x-6 ">
                    {/* Divider with Icon */}
                    <div className="hidden lg:flex flex-col items-center w-full lg:w-auto justify-center space-y-2">
                        <div className="border-l-2 border-yellow-400 h-24 lg:h-64"></div>
                        <span className="text-2xl text-yellow-500">✦</span>
                        <div className="border-l-2 border-yellow-400 h-24 lg:h-64"></div>
                    </div>

                    {/* Main Info */}
                    <div className="flex flex-col items-center  text-center lg:text-left space-y-4 lg:space-y-6">
                        {/* Name Section */}
                        <h1 className="lg:text-2xl text-xl font-semibold font-serif text-green-800">
                            Kanmani Samikannu
                        </h1>

                        {/* Title Section */}
                        <div className="text-center text-xs md:text-sm uppercase font-light tracking-widest">
                            <p> executive director , </p>
                            <p>trinity plus social trust</p>
                        </div>

                        {/* Contact Info Section */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-4 text-center mt-2">
                            <div>
                                <a href="https://www.trinityplus.org" className="text-xs md:text-sm text-gray-600 hover:text-black">
                                    🌐 www.trinityplus.org
                                </a>
                            </div>
                            <div>
                                <a href="mailto:trinityplussocial@gmail.com" className="text-xs md:text-sm text-gray-600 hover:text-black">
                                    ✉️ trinityplussocial@gmail.com
                                </a>
                            </div>
                            <div>
                                <p className="text-xs md:text-sm text-black">📞 +91 63802 623902</p>
                            </div>
                            <div>
                                <p className="text-xs md:text-sm text-black">📍 Tiruchirappalli, Tamil Nadu</p>
                            </div>
                        </div>

                        {/* Bio Section */}
                        <div className="max-w-[1100px] mx-auto p-4">
                            <p className="text-black mb-4 text-sm lg:text-base text-justify">
                                S. Kanmani Samikannu, the Executive Director at Trinity Plus Social Trust, embodies a profound dedication to education and community development. With a strong academic background, including an MA and M.Ed, Kanmani has amassed extensive experience across various facets of the educational sector. His tenure as a school principal, teacher, motivational speaker, community program developer, social activist, and career counselor reflects his diverse skill set and unwavering commitment to making a difference.                             </p>
                            <p className="text-black mb-4 text-sm lg:text-base text-justify">
                                Kanmani's vision for a more inclusive society, where education serves as a catalyst for personal and communal growth, guides his every endeavor. His belief in the transformative power of education fuels his passion for empowering individuals with knowledge and skills, particularly those from marginalized backgrounds.                             </p>
                            <p className="text-black mb-4 text-sm lg:text-base text-justify">
                                Through his role at Trinity Plus Social Trust, Kanmani endeavors to create meaningful change, one community at a time, fostering empowerment and uplifting those in need. His life message resonates with the belief that education is the cornerstone of progress, and by empowering individuals, we can shape a brighter future for all.
                            </p>

                            <div className="flex justify-center ">
                                <h1 className="text-[#1f783f] font-sans font-bold text-lg lg:text-2xl text-center">
                                    "Building Bridges, Transforming Lives"
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Footer />
        </>
    );
}

export default About;