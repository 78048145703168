import Header from "./Header";
import Aos from 'aos';
import 'aos/dist/aos.css';
import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import Footer from "./Footer";
import emailjs from 'emailjs-com'; // Add this import to enable email functionality

function Home() {
    const [isVisible, setIsVisible] = useState(false);
    const [countersVisible, setCountersVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.getElementById('target-section');
            if (section) {
                const sectionTop = section.getBoundingClientRect().top;
                const windowHeight = window.innerHeight || document.documentElement.clientHeight;

                if (sectionTop < windowHeight && sectionTop > 0) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        Aos.init({
            delay: 200, // Delay in ms (100ms = 0.1s)
            duration: 1000, // Animation duration in ms
            once: true, // Whether animation should happen only once - while scrolling down
        });
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setCountersVisible(true);
                    observer.disconnect(); // Stop observing once it's visible
                }
            },
            {
                threshold: 0.2, // Trigger when 20% of the section is visible
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    // State to control the visibility of the popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    // State to manage form data
    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        city: ''
    });

    // Open the popup when a button is clicked
    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    // Close the popup
    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    // Handle form input changes
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const serviceID = 'service_q03mml9';  // Replace with your EmailJS service ID
        const templateID = 'template_xzbjcdb';  // Replace with your EmailJS template ID
        const userID = 'BC01ebBgWP23GT72x';  // Replace with your EmailJS user ID

        emailjs.send(serviceID, templateID, formData, userID)
            .then((result) => {
                console.log('Email sent successfully:', result.text);
                alert('Email sent successfully!');
            })
            .catch((error) => {
                console.error('Error sending email:', error.text);
                alert('Failed to send email.');
            });

        // Optionally reset form fields
        setFormData({
            name: '',
            phoneNumber: '',
            email: '',
            city: ''
        });

        // Close the popup after submission
        handleClosePopup();
    };
    const images = [
        "img/Trinity-Plus-Social-Trust.jpeg.jpg",
        "img/IMG_20220506_125035.jpg",
        "img/IMG_20210421_110452.jpg",
        "img/1(1).jpg"
        // Add more image paths as needed
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [images.length]);
    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };



    return (
        <>
            <Header></Header>
            <section>
                <div className="relative">
                    <img
                        src={images[currentIndex]}
                        alt={`Slide ${currentIndex + 1}`}
                        className="w-full h-[250px] lg:h-[600px] object-cover"
                    />
                    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-slate-50 bg-opacity-30">
                        <h1
                            data-aos="fade-up"
                            className="bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 bg-clip-text text-transparent text-3xl md:text-5xl lg:text-6xl font-bold text-center px-4 md:px-8 font-playfair"
                        >
                            Let's Create a Better<br /> Tomorrow
                        </h1>

                        <button
                            onClick={handleOpenPopup}
                            className="mt-4 md:w-auto px-6 py-3 md:px-8 md:py-4 bg-blue-500 text-white font-semibold rounded-[10px] hover:bg-blue-700 transition duration-300 flex justify-center"
                        >
                            Join With Us
                        </button>
                    </div>

                    {/* Previous Button */}
                    <button
                        onClick={handlePrevious}
                        className="absolute left-2 top-1/2 transform -translate-y-1/2 text-black bg-white bg-opacity-20 p-1 sm:p-2 lg:p-3 rounded-full hover:bg-opacity-50 transition-all"
                        aria-label="Previous"
                    >
                        <i className="fa-solid fa-chevron-left text-lg sm:text-xl lg:text-2xl"></i>
                    </button>

                    {/* Next Button */}
                    <button
                        onClick={handleNext}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black bg-white bg-opacity-20 p-1 sm:p-2 lg:p-3 rounded-full hover:bg-opacity-50 transition-all"
                        aria-label="Next"
                    >
                        <i className="fa-solid fa-chevron-right text-lg sm:text-xl lg:text-2xl"></i>
                    </button>


                </div>




                <div className="py-10 bg-white">
                    <section id="target-section">
                        <h1
                            className={`text-center text-lg md:text-2xl lg:text-4xl font-bold font-serif tracking-wider text-red-500 transform transition duration-700 ${isVisible ? '-translate-y-2 opacity-100' : 'translate-y-full opacity-0'
                                }`}
                        >
                            Trinity Plus Your Success Plus
                        </h1>
                    </section>
                    <div className="flex justify-center items-center p-4">
                        <h1 className="text-xs lg:text-xl text-[#4d4d4d] font-sans lg:transition-transform lg:transform lg:hover:scale-110 lg:duration-300">
                            "FOCUSED ON 6 SDGS FOR IMPACTFUL CHANGE"
                        </h1>
                    </div>
                    <div className="flex flex-wrap justify-center items-center gap-x-4 gap-y-4 sm:gap-x-6 lg:mt-5">
                        <img
                            src="img/Sustainable_Development_Goal_1.png"
                            alt="SDG 1"
                            className="w-32 h-32 sm:w-40 sm:h-40  object-contain transition-transform transform hover:scale-110 hover:shadow-[0px_0px_30px_1px_#00000040]  duration-300 rounded-[10px]"
                        />
                        <img
                            src="img/Sustainable_Development_Goal_2.png"
                            alt="SDG 2"
                            className="w-32 h-32 sm:w-40 sm:h-40  object-contain transition-transform transform hover:scale-110 hover:shadow-[0px_0px_30px_1px_#00000040]  duration-300 rounded-[10px]"
                        />
                        <img
                            src="img/Sustainable_Development_Goal_03GoodHealth.svg.png"
                            alt="SDG 3"
                            className="w-32 h-32 sm:w-40 sm:h-40  object-contain transition-transform transform hover:scale-110 hover:shadow-[0px_0px_30px_1px_#00000040]  duration-300 rounded-[10px]"
                        />
                        <img
                            src="img/Sustainable_Development_Goal_4.png"
                            alt="SDG 4"
                            className="w-32 h-32 sm:w-40 sm:h-40  object-contain transition-transform transform hover:scale-110 hover:shadow-[0px_0px_30px_1px_#00000040]  duration-300 rounded-[10px]"
                        />
                        <img
                            src="img/Sustainable_Development_Goal_05GenderEquality.svg.png"
                            alt="SDG 5"
                            className="w-32 h-32 sm:w-40 sm:h-40  object-contain transition-transform transform hover:scale-110 hover:shadow-[0px_0px_30px_1px_#00000040]  duration-300 rounded-[10px]"
                        />
                        <img
                            src="img/Sustainable_Development_Goal_8.png"
                            alt="SDG 8"
                            className="w-32 h-32 sm:w-40 sm:h-40  object-contain transition-transform transform hover:scale-110 hover:shadow-[0px_0px_30px_1px_#00000040]  duration-300 rounded-[10px]"
                        />
                    </div>



                </div>

                <div className="bg-green-800 p-2 flex flex-col lg:flex-row items-center lg:items-start">
                    <div className="w-full lg:w-2/4 lg:pr-10 lg:ml-40 mb-6 lg:mb-0">
                        <h2 className="text-white text-2xl font-bold mb-4 text-center lg:text-left mt-5">Genesis of TRINITY PLUS SOCIAL TRUST</h2>
                        <p className="p-4 bg-[#ddf4dd] rounded-[10px] text-black text-lg text-justify shadow-xl">
                            <span className="font-semibold">Trinity Plus Social Trust</span> is a dedicated non-profit organization based in Tamil Nadu, established on 10/11/2008 <span className="font-semibold">(Registration No: 617/2008)</span>. Our mission is to uplift marginalized and socially weaker sections through comprehensive programs in <span className="font-semibold">Education, Healthcare, Agriculture, and Livelihood.</span> We have successfully empowered over 300,000 students with <span className="font-semibold">career guidance, counseling, and job-oriented skill development.</span> Our healthcare initiatives, like the <span className="font-semibold">Doorstep Doctor program,</span> provide essential services to underserved communities. We support farmers through sustainable practices and initiatives like the <span className="font-semibold">Elangandru FPO and One Plus One Goat</span> Scheme. Our <span className="font-semibold">de-addiction and family counseling services</span> strengthen community bonds. Collaborating with local authorities and NGOs, we strive for holistic community development. Our commitment to transparency and accountability ensures trust and effective utilization of resources.
                        </p>
                    </div>
                    <div className="w-full lg:w-1/3">
                        <img
                            src="img/Bhaskaran-Trinity-Plus-qmlkkroes6ej5k1665r5p1i8qqgm5a611p0wqbmbuw.jpeg"
                            alt="Founder"
                            className="rounded-lg shadow-lg mx-auto lg:mx-0 mt-5 mb-5"
                        />
                    </div>
                </div>

                <div className="bg-white p-2 flex flex-col lg:flex-row items-center lg:items-start">
                    {/* Left Image Section */}
                    <div className="w-full lg:w-1/4 lg:ml-40 mb-6 lg:mb-0">
                        <img
                            src="img/Trinity-Plus-Volunteer-768x768.png"
                            alt="Volunteer"
                            className="rounded-lg shadow-xl mx-auto lg:mx-0"
                        />
                    </div>

                    {/* Right Text Section */}
                    <div className="w-full lg:w-1/2 lg:pl-10 mb-6 lg:mb-0">
                        <h3 className="text-2xl font-bold text-black text-center lg:text-left mt-5">
                            Become a Volunteer
                        </h3>
                        <hr className=" border-[#F7C35F] border-t-2 w-[220px] md:w-[220px] mt-1 mx-auto lg:mx-0" />
                        <h2 className="text-2xl mt-2 font-bold text-green-600 mb-4 text-center lg:text-left">
                            Join us for a Better Life and Beautiful Future
                        </h2>
                        <p className="text-gray-600 text-lg leading-7 mb-6 text-justify">
                            <span className="font-medium">TRINITY PLUS SOCIAL TRUST </span>
                            is calling all passionate individuals who want to make a real difference in the lives of marginalized communities. We offer a variety of volunteer opportunities where you can use your skills and talents to empower and uplift those in need.
                        </p>

                        <button
                            onClick={handleOpenPopup}
                            className="bg-yellow-500 text-white px-5 py-2 rounded-full font-bold hover:bg-yellow-600 transition duration-300 mx-auto lg:ml-0 block"
                        >
                            Apply Now
                        </button>

                    </div>

                </div>

                <div className="bg-white py-8" ref={sectionRef}>
                    <div className="container mx-auto flex flex-wrap justify-around items-center">
                        <div className="text-center mb-6 lg:mb-0 w-full sm:w-1/2 lg:w-1/4">
                            <h2 className="text-4xl font-bold text-yellow-500">
                                {countersVisible ? <CountUp end={50} duration={3} /> : 0}+
                            </h2>
                            <p className="text-gray-600 text-lg">Volunteers</p>
                        </div>
                        <div className="text-center mb-6 lg:mb-0 w-full sm:w-1/2 lg:w-1/4">
                            <h2 className="text-4xl font-bold text-yellow-500">
                                {countersVisible ? <CountUp end={980} duration={3} /> : 0}+
                            </h2>
                            <p className="text-gray-600 text-lg">Donations</p>
                        </div>
                        <div className="text-center mb-6 lg:mb-0 w-full sm:w-1/2 lg:w-1/4">
                            <h2 className="text-4xl font-bold text-yellow-500">
                                {countersVisible ? <CountUp end={10} duration={3} /> : 0}+
                            </h2>
                            <p className="text-gray-600 text-lg">Projects</p>
                        </div>
                        <div className="text-center mb-6 lg:mb-0 w-full sm:w-1/2 lg:w-1/4">
                            <h2 className="text-4xl font-bold text-yellow-500">
                                {countersVisible ? <CountUp end={25} duration={3} /> : 0}+
                            </h2>
                            <p className="text-gray-600 text-lg">Missions</p>
                        </div>
                    </div>
                </div>
                {/* Popup form */}
                {isPopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
                        <div className="relative max-w-md mx-auto mt-10 p-6 mb-10 bg-gradient-to-r from-green-300 to-green-200 rounded-[20px] shadow-md">
                            {/* Close button positioned in the top right corner */}
                            <div className="absolute top-7 right-3">
                                <img
                                    onClick={handleClosePopup}
                                    src="img\Close.png"
                                    alt="Close"
                                    className="w-6 h-6 cursor-pointer"
                                />
                            </div>

                            <h2 className="text-2xl font-bold mb-6 font-serif text-center">Join With Us</h2>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                {/* Name */}
                                <div>
                                    <label className="block text-sm font-medium mb-1" htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-500"
                                        placeholder="Enter your name"
                                        required
                                    />
                                </div>

                                {/* Phone Number */}
                                <div>
                                    <label className="block text-sm font-medium mb-1" htmlFor="phoneNumber">Phone Number</label>
                                    <input
                                        type="tel"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-500"
                                        placeholder="Enter your phone number"
                                        required
                                    />
                                </div>

                                {/* Email */}
                                <div>
                                    <label className="block text-sm font-medium mb-1" htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-500"
                                        placeholder="Enter your email"
                                        required
                                    />
                                </div>

                                {/* City */}
                                <div>
                                    <label className="block text-sm font-medium mb-1" htmlFor="city">City</label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-500"
                                        placeholder="Enter your city"
                                        required
                                    />
                                </div>

                                {/* Submit Button */}
                                <button
                                    type="submit"
                                    className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-400 text-white font-bold rounded-md transition-all"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                )}



            </section>
            <Footer />
        </>
    );
}

export default Home;