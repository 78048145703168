import Header from "./Header";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import Footer from "./Footer";

function Projects() {
    useEffect(() => {
        Aos.init({
            // Global settings:
            delay: 200, // Delay in ms (100ms = 0.1s)
            duration: 1000, // Animation duration in ms
            once: true, // Whether animation should happen only once - while scrolling down
        });
    }, []);
    return (
        <>
            <Header></Header>
            <section className="relative bg-white">
                <div  className="relative">
                    <img
                        src="img/people-2557399_1280.jpg"
                        alt="About Us"
                        className="w-full object-cover h-96 bg-black bg-opacity-50"
                    />
                    <div data-aos="fade-up" className="absolute bottom-4 text-center left-4 md:left-8 lg:left-12 top-1/2 transform -translate-y-1/2 p-4 font-bold text-white text-4xl md:text-5xl lg:text-7xl">
                        Our Projects
                    </div>
                </div>

            </section>
            <div className="bg-white p-6 max-w-7xl mx-auto">
                {/* Section 1 */}
                <div className="flex flex-col lg:flex-row items-center mb-8">
                    <div  className="w-full lg:w-1/2">
                        <img
                            src="img/a-boy-hugging-a-pile-of-books-free-photo.jpg"
                            alt="Child with books"
                            className="rounded-lg "
                        />
                    </div>
                    <div className="w-full lg:w-1/2 lg:pl-12">
                        <h2 className="text-3xl font-bold text-yellow-500 mb-4 ">
                            Children Education Welfare Services
                        </h2>
                        <p className="text-gray-700 text-lg text-justify">
                            Our TRINITY PLUS Social Trust knows well that the children are the
                            future society and it is our responsibility to provide complete,
                            comprehensive and intensive, quality education to the children at
                            the schooling will help to provide potential human resources to the
                            upcoming society. Hence we decided to provide educational assistance
                            to the poor school going children to continue their schooling by
                            providing free educational materials and we have distributed free
                            note books and teaching materials to school students at the age
                            group of 8-11 yrs old. Over 32 students from poor and disadvantaged
                            community in the working area and nearby urban slum area are
                            benefited by these materials. This programme is mobilized the
                            resources from the community level and also the major philanthropist
                            donor also supported this program with necessary equipments and
                            financial supports.
                        </p>
                    </div>
                </div>

                {/* Section 2 */}
                <div className="flex flex-col lg:flex-row items-center mb-5">
                    <div className="w-full lg:w-1/2">
                        <h2 className="text-3xl font-bold text-yellow-500 mb-4">
                            Children Education Welfare Services
                        </h2>
                        <p className="text-gray-700 text-lg text-justify">
                            Our TRINITY PLUS Social Trust knows well that the children are the
                            future society and it is our responsibility to provide complete,
                            comprehensive and intensive, quality education to the children at
                            the schooling will help to provide potential human resources to the
                            upcoming society. Hence we decided to provide educational assistance
                            to the poor school going children to continue their schooling by
                            providing free educational materials and we have distributed free
                            note books and teaching materials to school students at the age
                            group of 8-11 yrs old. Over 32 students from poor and disadvantaged
                            community in the working area and nearby urban slum area are
                            benefited by these materials. This programme is mobilized the
                            resources from the community level and also the major philanthropist
                            donor also supported this program with necessary equipments and
                            financial supports.
                        </p>
                    </div>
                    <div className="w-full lg:w-1/2 lg:pl-12">
                        <img
                            src="img/QNET-Journey-women-entrepreneurs-scaled.jpg"
                            alt="Woman with laptop"
                            className="rounded-lg"
                        />
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row items-center mb-8">
                    <div className="w-full lg:w-1/2">
                        <img
                            src="img/Internships-Define-Importance-and-Benefits.jpg"
                            alt="Child with books"
                            className="rounded-lg"
                        />
                    </div>
                    <div className="w-full lg:w-1/2 lg:pl-12">
                        <h2 className="text-3xl font-bold text-yellow-500 mb-4">
                            Youths Skill Development (YSD) Trainings
                        </h2>
                        <p className="text-gray-700 text-lg text-justify">
                            TRINITY PLUS Social Trust focused special programs for developing positive, potential benefits of youth powers by providing Trade based Skill Development Trainings and knowledge exposures. For this we have established the a special programs for providing YSD Training exposures particularly for youths on Trade based, self employable training.  With the Financial Support of “TAMILNADU SKILL DEVELOPMENT CORPORATION (TNSDC), Govt of Tamilnadu” we have conducted a “Entrepreneurship Development Training (EDT) Program to the 150 more deprived youths and rural women community in the working blocks of Tiruchirappalli and Tanjavur District. By this Skill Development training program facilitated over 200 more rural Youths, Adolescent girls from BPL (Below Poverty Line) and supported for 15 days to 3 months training courses on various Vocational Trades including Tailoring, Fashion Designing, CCTV Camera Installation, DTP, Two Wheeler / Three Wheeler Service Technician, Retail Sales Associate, CRM Domestic Non voice, and the curriculum also includes the Entrepreneurship Qualities, How to become Successful Entrepreneurs, Selection of Business etc., Marketing Methods, Job Placement Services will also be arranged about 75 % of candidates successfully.
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Projects;