import React, { useState, useEffect } from 'react';

const ImageSlider = () => {
  const images = [
    { src: 'img/Screenshot 2024-09-14 142549.png', alt: 'Event 1' },
    { src: 'img/Screenshot 2024-09-14 142611.png', alt: 'Event 2' },
    { src: 'img/Screenshot 2024-09-14 142624.png', alt: 'Event 3' },
    { src: 'img/Screenshot 2024-09-14 142653.png', alt: 'Event 4' }
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // change image every 3 seconds

    return () => clearInterval(interval); // clean up interval on component unmount
  }, [images.length]);

  return (
    <div className="block lg:hidden p-2 sm:p-4"> {/* Visible only on mobile and hidden on large screens */}
      <div className="mx-auto relative w-[300px] h-[300px]"> {/* Fixed size for mobile */}
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
              }`}
          >
            <img
              src={image.src}
              alt={image.alt}
              className="w-[300px] h-[300px] object-cover rounded-[5px] sm:rounded-[10px]" // Fixed size for images
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
