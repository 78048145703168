import React, { useState } from 'react';

function Header() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <header className="bg-white text-white py-2 px-4 sm:px-8 flex flex-col md:flex-row justify-between items-center lg:top-0 lg:z-10 sticky shadow-lg">
            <div className="flex items-center justify-between w-full md:w-auto mt-2 sm:mt-4 md:mt-0">
                <a href="/" aria-label="Home">
                    <img
                        src="img/trinity-logo.png"
                        alt="Trinity Plus Logo"
                        className="h-12 sm:h-16 lg:h-20 object-contain transition-transform transform hover:scale-110 duration-300"
                    />
                </a>
                <div className="flex justify-end w-auto md:hidden">
                    <button
                        className="block text-[#1f783f] focus:outline-none border-2 p-1 sm:p-2 lg:p-3 rounded-md"
                        onClick={toggleMenu}
                        aria-label="Toggle navigation"
                    >
                        <i
                            className={`${isOpen ? "fas fa-times" : "fas fa-bars"} text-xl sm:text-2xl lg:text-3xl transition-transform duration-300`}
                        ></i>
                    </button>
                </div>
            </div>
            <div className="w-full md:flex md:justify-center">
                <nav className={`${isOpen ? "block" : "hidden"} md:flex md:items-center mt-4 md:mt-0 w-full md:w-auto`}>
                    <ul className="flex flex-col text-center md:flex-row space-y-4 md:space-y-0 md:space-x-6 lg:space-x-8">
                        <li>
                            <a href="/" className="text-[#1f783f] font-bold lg:text-lg md:text-base hover:text-yellow-500 transition-all">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="/about" className="text-[#1f783f] font-bold lg:text-lg md:text-base hover:text-yellow-500 transition-all">
                                About Us
                            </a>
                        </li>
                        <li>
                            <a href="/projects" className="text-[#1f783f] font-bold lg:text-lg md:text-base hover:text-yellow-500 transition-all">
                                Projects
                            </a>
                        </li>
                        <li>
                            <a href="/services" className="text-[#1f783f] font-bold lg:text-lg md:text-base hover:text-yellow-500 transition-all">
                                Services
                            </a>
                        </li>
                        <li>
                            <a href="/contact" className="text-[#1f783f] font-bold lg:text-lg md:text-base hover:text-yellow-500 transition-all">
                                Contact
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="mt-4 lg:mr-24 flex space-x-2">
                <button 
                    className="bg-gradient-to-r from-green-700 to-green-200 text-white font-bold py-2 px-6 rounded-full shadow-lg transition-all w-full md:w-auto flex items-center justify-center space-x-2 hover:text-[rgb(0,0,0)] transform hover:scale-110"
                >
                    <span>Donate</span>
                    <i className="fa-solid fa-hand-holding-dollar" style={{ color: '#000000' }}></i>
                </button>
            </div>
        </header>
    );
}

export default Header;
