import { Route, Routes, useLocation } from "react-router-dom";
import './App.css';
import Home from './Componen/Home';
import Projects from './Componen/Projects';
import About from "./Componen/About";
import Services from "./Componen/Services";
import ContactForm from "./Componen/ContactForm";
import PrivacyPolicy from "./Componen/Privacy";


function App() {
  const location = useLocation();
  return (
    <>

      <Routes location={location} key={location.pathname}>
        <Route exact path="/" Component={Home} />
        <Route exact path="/privacy" Component={PrivacyPolicy} />
        <Route exact path="/about" Component={About} />
        <Route exact path="/projects" Component={Projects} />
        <Route exact path="/services" Component={Services} />
        <Route exact path="/contact" Component={ContactForm} />


      </Routes>
    </>
  );
}

export default App;
