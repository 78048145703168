import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Header from './Header';
import Footer from './Footer';

const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_q03mml9', // Replace with your EmailJS service ID
                'template_8d5f1dr', // Replace with your EmailJS template ID
                form.current,
                'BC01ebBgWP23GT72x' // Replace with your EmailJS public key
            )
            .then(
                (result) => {
                    alert('Message sent successfully!');
                    console.log(result.text);
                },
                (error) => {
                    alert('An error occurred, please try again.');
                    console.log(error.text);
                }
            );

        // Clear the form fields after submission
        e.target.reset();
    };

    return (
        <>
            <Header />
            <div
                className="relative bg-cover bg-center"
                style={{ backgroundImage: "url('img/bg-3.png')" }}>
                <section className="min-h-screen text-black flex items-center justify-center p-8">
                    <div className="max-w-4xl w-full grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {/* Left Section - Contact Info */}
                        <div className="flex flex-col justify-center space-y-8">
                            <div>
                                <button className="px-4 py-2 rounded-full bg-[#ffffff40] text-sm uppercase tracking-wider shadow-lg">
                                    Contact
                                </button>
                            </div>
                            <h1 className="text-4xl text-slate-700 font-bold">Trinity Plus Social Trust</h1>
                            <div className="space-y-6">
                                <div className="flex items-center space-x-4 p-4 bg-[#ffffff40] rounded-lg">
                                    <span className="text-2xl">📩</span>
                                    <div>
                                        <h3 className="font-bold">Email us</h3>
                                        <p className="text-black">trinityplussocial@gmail.com</p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-4 p-4 bg-[#ffffff40] rounded-lg">
                                    <span className="text-2xl">📞</span>
                                    <div>
                                        <h3 className="font-bold">Call us</h3>
                                        <p className="text-black">91+ 9514 222122</p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-4 p-4 bg-[#ffffff40] rounded-lg">
                                    <span className="text-2xl">📍</span>
                                    <div>
                                        <h3 className="font-bold">Our location</h3>
                                        <p className="text-black">Tiruchirappalli, Tamilnadu</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Right Section - Contact Form */}
                        <div className="bg-[#ffffff40] p-8 rounded-lg shadow-2xl">
                            <form ref={form} onSubmit={sendEmail} className="space-y-6">
                                <div>
                                    <label htmlFor="name" className="block text-sm font-medium text-black">Name</label>
                                    <input
                                        type="text"
                                        name="user_name"
                                        id="name"
                                        className="w-full p-3 mt-2 bg-[#ffffff40] rounded-lg focus:ring-2 focus:ring-white outline-none"
                                        placeholder="Your name"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-black">Email</label>
                                    <input
                                        type="email"
                                        name="user_email"
                                        id="email"
                                        className="w-full p-3 mt-2 bg-[#ffffff40] rounded-lg focus:ring-2 focus:ring-white outline-none"
                                        placeholder="Your email"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="message" className="block text-sm font-medium text-black">Message</label>
                                    <textarea
                                        name="message"
                                        id="message"
                                        rows="4"
                                        className="w-full p-3 mt-2 bg-[#ffffff40] rounded-lg focus:ring-2 focus:ring-white outline-none"
                                        placeholder="Your message"
                                        required
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="w-full py-3 bg-white text-black rounded-lg font-semibold hover:text-white hover:bg-black transition"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default ContactForm;
